<template>
  <div>
  <!-- <v-card flat> -->
    <v-app-bar
      app
      absolute
      color="white"
      prominent
    >
    <!-- scroll-target="#scrolling-techniques-4" -->
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="drawer = true"></v-app-bar-nav-icon>

      <!-- <v-container class="mx-auto py-0"> -->
      <!-- <v-btn text color="pink" dark small absolute right fab>
        <span style="color:#474747">02-999-9999</span><v-icon>phone</v-icon>
        <img>
      </v-btn> -->
      <!-- <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn text dark fab>
          <img src="../../assets/SoftwareClinicIcon/kisspng-telephone-call-home-business-phones-email-mobile-call-icon-5acda2945a4a30.8043904715234259403698.jpg" height="30" weight="30"/>
        </v-btn>
      </v-toolbar> -->
      <v-container
        class="pa-0"
        fluid
        fill-height>
        <v-row align="center" class="pt-0">
          <v-img
            :src="require('@/assets/MeeTeeMeeNgern/logo.jpg')"
            class="mr-5"
            contain
            height="100"
            width="100"
            max-width="100"
            @click="$router.push('/')"
          />
          <v-spacer />
          <v-btn
            :class="[$vuetify.breakpoint.lgAndUp ? 'hidden-sm-and-down text-h3' : 'hidden-sm-and-down text-h5']"
            text
            x-large
            @click="Home()"
          >
            <span style="color:#474747">{{ $t('MeeTeeMeeNgern1.Home') }}</span>
          </v-btn>
          <v-menu
            open-on-hover
            rounded="b-xl"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- v-bind="link" -->
              <v-btn
                v-bind="attrs"
                :class="[$vuetify.breakpoint.lgAndUp ? 'hidden-sm-and-down text-h3' : 'hidden-sm-and-down text-h5']"
                text
                v-on="on"
                x-large
              >
                <span style="color:#474747">{{ $t('MeeTeeMeeNgern1.menu1') }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in product"
                :key="index"
                @click="ProductPage(item)"
                v-show="index===0"
              >
              <!-- Golf  add v-show="index===0" เพื่อไม่แสดง ขายฝาก -->
                <v-list-item-title><span class="display-1">{{ item.product_name }}</span></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            open-on-hover
            rounded="b-xl"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- v-bind="link" -->
              <v-btn
                v-bind="attrs"
                :class="[$vuetify.breakpoint.lgAndUp ? 'hidden-sm-and-down text-h3' : 'hidden-sm-and-down text-h5']"
                text
                v-on="on"
                x-large
              >
                <span style="color:#474747">{{ $t('MeeTeeMeeNgern1.branch') }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in itemsMenu2"
                :key="index"
                :to="item.link"
              >
                <v-list-item-title><span class="display-1">{{ item.title }}</span></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            open-on-hover
            rounded="b-xl"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- v-bind="link" -->
              <v-btn
                v-bind="attrs"
                :class="[$vuetify.breakpoint.lgAndUp ? 'hidden-sm-and-down text-h3' : 'hidden-sm-and-down text-h5']"
                text
                v-on="on"
                x-large
              >
                <span style="color:#474747">{{ $t('MeeTeeMeeNgern1.aboutUsHeader') }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in itemsMenu3"
                :key="index"
                :to="item.link"
              >
                <v-list-item-title><span class="display-1">{{ item.title }}</span></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            :class="[$vuetify.breakpoint.lgAndUp ? 'hidden-sm-and-down text-h3' : 'hidden-sm-and-down text-h5']"
            text
            x-large
            @click="Articles()"
          >
            <span style="color:#474747">{{ $t('MeeTeeMeeNgern1.Articles') }}</span>
          </v-btn>
          <!-- <v-btn
            :class="[$vuetify.breakpoint.lgAndUp ? 'hidden-sm-and-down gray--text text-h3' : 'hidden-sm-and-down gray--text text-h5']"
            text
            x-large
            @click="Promotions()"
          >
            <span style="color:#474747">{{ $t('MeeTeeMeeNgern1.promotions') }}</span>
          </v-btn> -->
          <v-btn
            :class="[$vuetify.breakpoint.lgAndUp ? 'hidden-sm-and-down gray--text text-h3' : 'hidden-sm-and-down gray--text text-h5']"
            text
            x-large
            @click="Subscribe()"
          >
            <span style="color:#474747">{{ $t('MeeTeeMeeNgern1.Subscribe') }}</span>
          </v-btn>
          <v-btn
            :class="[$vuetify.breakpoint.lgAndUp ? 'hidden-sm-and-down gray--text text-h3' : 'hidden-sm-and-down gray--text text-h5']"
            text
            x-large
            @click="workWithUs()"
          >
            <span style="color:#474747">{{ $t('MeeTeeMeeNgern1.workWithUs') }}</span>
          </v-btn>
          <!-- <languagePicker @selectLanguageEvent="selectLanguage"/> -->
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title @click="$router.push({path: '/'})">{{ $t('MeeTeeMeeNgern1.Home') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push({path: '/Products'})">{{ $t('MeeTeeMeeNgern1.menu1') }}</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="$router.push({path: '/BranchGSB'})">{{ $t('MeeTeeMeeNgern1.BranchGSBHeader') }}</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title @click="$router.push({path: '/BranchBCP'})">{{ $t('MeeTeeMeeNgern1.BranchBCPHeader') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push({path: '/BranchTIP'})">{{ $t('MeeTeeMeeNgern1.BranchTIPHeader') }}</v-list-item-title>
          </v-list-item> -->
          <v-list-item>
            <v-list-item-title @click="$router.push({path: '/About'})">{{ $t('MeeTeeMeeNgern1.aboutUsHeader') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push({path: '/Goal'})">{{ $t('MeeTeeMeeNgern1.GoalHeader') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="Articles()">{{ $t('MeeTeeMeeNgern1.Articles') }}</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title @click="Promotions()">{{ $t('MeeTeeMeeNgern1.promotions') }}</v-list-item-title>
          </v-list-item> -->
          <v-list-item>
            <v-list-item-title @click="Subscribe()">{{ $t('MeeTeeMeeNgern1.Subscribe') }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="workWithUs()">{{ $t('MeeTeeMeeNgern1.workWithUs') }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  // Utilities
  import {
    mapGetters,
    mapMutations,
  } from 'vuex'
  import languagePicker from 'components/navigation/languagePicker.vue'

  export default {
    components: {
      languagePicker,
    },
    data: () => ({
      itemsMenu1: [
        { skey: 1, title: 'สินเชื่อที่ดิน' },
        { skey: 2, title: 'ขายฝาก' },
      ],
      itemsMenu2: [
        { title: 'ค้นหาสาขาธนาคารออมสิน', link: 'BranchGSB' },
        /* { title: 'ค้นหาสาขา TIP', link: 'BranchTIP' },
        { title: 'ค้นหาสาขา BCP', link: 'BranchBCP' }, */
      ],
      itemsMenu3: [
        /* { title: 'ความเป็นมา', link: 'About' }, */
        { title: 'วิสัยทัศน์และพันธกิจ', link: 'Goal' },
      ],
      layout: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      layoutProducgt: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      page: 1,
      product: [],
      /* product: require('@/data/product.json'), */
      drawer: false,
      group: '',
    }),

    async created () {
      this.getProductList(localStorage.getItem('language'))
    },
    name: 'CoreAppBar',

    computed: {
      ...mapGetters(['links']),
      height () {
        console.log(this.$vuetify.breakpoint.name, 'this.$vuetify.breakpoint.name')
        console.log(this.$vuetify.breakpoint, 'this.$vuetify.breakpoint.ndddame')
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
    },

    methods: {
      ...mapMutations(['toggleDrawer']),
      onClick (e, item) {
        e.stopPropagation()

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
      },
      login () {
        this.$router.push('/login')
      },
      Subscribe () {
        this.$router.push('/Subscribe')
      },
      workWithUs () {
        this.$router.push('/workWithUs')
      },
      selectLanguage (value) {
        this.$emit('selectLanguageEvent', value)
        this.getProductList(value)
      },
      About () {
        this.$router.push('/About')
      },
      Promotions () {
        this.$router.push('/Promotions')
      },
      Articles () {
        this.$router.push('/Articles')
      },
      BranchGSB () {
        this.$router.push('/BranchGSB')
      },
      BranchTIP () {
        this.$router.push('/BranchTIP')
      },
      BranchBCP () {
        this.$router.push('/BranchBCP')
      },
      ProductPage (value) {
        if (this.$route.path !== '/Product') {
          this.$router.push({ name: 'Product', params: { propValue: value } })
        } else {
          this.$router.push({ name: 'Product', params: { propValue: value } })
        }
      },
      async getProductList (language) {
        // this.$swal({type: 'warning', html: 'language_skey ' + language})
        var lang = 1
        if (language === 'en') {
          lang = 2
        } else {
          lang = 1
        }
        language = 'th'
        await this.axios.post(process.env.VUE_APP_API + '/product/retrieveProductList' + process.env.VUE_APP_DATABASE_TYPE, {
          lang: language,
        })
          .then(response => {
            this.product = response.data.data
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      Home () {
        this.$router.push('/')
      },
    },
  }
</script>
<style scoped>
  button.v-btn[disabled] {
    opacity: 0.6;
  }
</style>
